{
    "ca": {
        "titol": "Eleccions EUA",
        "titol_enquesta": "Enquesta EUA",
        "majoria": "Majoria absoluta",
        "delegats": "Delegats",
        "mes_dades": "Més dades",
        "mes": "Més",
        "escrutini": "Escrutini",
        "candidat_democrata": "Kamala Harris",
        "candidat_republica": "Donald Trump"
    },
    "es": {
        "titol": "Elecciones EUA",
        "titol_enquesta": "Encuesta EUA",
        "majoria": "Mayoría absoluta",
        "delegats": "Delegados",
        "mes_dades": "Más datos",
        "mes": "Más",
        "escrutini": "Escrutinio",
        "candidat_democrata": "Kamala Harris",
        "candidat_republica": "Donald Trump"
    }
}